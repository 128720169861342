<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="ListIcon" size="30" class="mr-50" /> Avaliar
                Desafios
              </h1>
              <h4 class="mt-1">Selecione um desafio para avaliar:</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-input-group class="input-group-merge mb-2">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input placeholder="Pesquisar" />
    </b-input-group>

    <b-card no-body class="mb-0">
      <b-table
        ref="refUserListTable"
        style="min-height: 50vw"
        class="position-relative"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolverStatusVariant(data.item.approved)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.approved) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{ name: 'pacienteEdit', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->
            <!--Mostrar apenas se tiver alguma consulta marcada -->

            <b-dropdown-item
              :to="{
                name: obterUrlAvaliar(data.item.modelo),
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Avaliar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BCol,
  BRow,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import challengeService from '@/services/challengeService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BCol,
    BRow,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  methods: {
    // resolveUserRoleVariant: role => {
    //   if (role === 'paciente') return 'primary'
    //   if (role === 'author') return 'warning'
    //   if (role === 'maintainer') return 'success'
    //   if (role === 'editor') return 'info'
    //   if (role === 'admin') return 'danger'
    //   return 'primary'
    // },
    // resolveUserRoleIcon: role => {
    //   if (role === 'paciente') return 'UserIcon'
    //   if (role === 'author') return 'SettingsIcon'
    //   if (role === 'maintainer') return 'DatabaseIcon'
    //   if (role === 'editor') return 'Edit2Icon'
    //   if (role === 'admin') return 'ServerIcon'
    //   return 'UserIcon'
    // },
    resolveUserStatusText: status => {
      if (status === true) return 'aprovado'
      if (status === false) return 'Não Aprovado'
      return ''
    },

    resolverStatusVariant: status => {
      if (status === true) return 'success'
      if (status === false) return 'warning'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return 'primary'
    },
    prepareRequest(obj) {
      return {
        ...obj,
        page: 1,
        itemsPerPage: 100,
      }
    },
    async fetchData() {
      showSpinner()
      this.tableItems = await challengeService.getForReview(
        this.prepareRequest({ name: this.searchQuery })
      )
      hideSpinner()
    },
    obterUrlAvaliar(modelo) {
      if (modelo === 'Arboturma') return 'avaliarArboturma'
      if (modelo === 'Janelas e Tanques') return 'avaliarJanelaTanque'
      if (modelo === 'Patrulha em Foco') return 'avaliarPatrulha'
      return ''
    },
  },
  data: () => ({
    tableItems: [],
    perPage: 10,
    totalItems: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    tableColumns: [
      { key: 'titulo', label: 'Nome', sortable: true },
      { key: 'modelo', label: 'Modelo', sortable: true },
      { key: 'aluno', label: 'Aluno(s)', sortable: true },
      { key: 'status', label: 'Status', sortable: true },
      // { key: 'Tipo', label: 'Tipo', sortable: true },
      // { key: 'Duracao', label: 'Duração', sortable: true },
      // { key: 'Instituição', label: 'Instituição', sortable: true },
      // { key: 'Autor', label: 'Autor', sortable: true },
      // { key: 'status', sortable: true },
      { key: 'actions', label: 'Ações', sortable: false },
    ],
  }),
  mounted() {
    this.fetchData()
  },
}
</script>
